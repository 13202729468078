.heading {
    color: #50555b;
    margin-left: 1rem;
}

.iframe {
    display: block;
    width: 100%;
    min-height: 800px;
}

.tabRow {
    display: flex;
}
