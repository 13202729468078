.header {
  background-image: url('../../../images/corp_practice_banner.png');
  color: #FFFFFF;
  display: flex;
  justify-content: space-evenly;
  height: 95px;
  padding: 16px;
}

.name {
  font-size: 25px;
}

.image_icon {
  width: 16px;
  margin-right: 4px;
}

.fa_icon {
  color: #50545a;
  margin-right: 4px;
}

.nav {
  padding: 10px;
  border-bottom: 1px solid #dee5e7;;
}