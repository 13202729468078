.compliance-column {
  padding-right: 16px;
}

.category-body {
  background-color: rgb(250, 250, 250);
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding-top: 16px;
}

.category-header {
  color: #50555b;
  font-weight: 700;
}

.header-text,
.training-header {
  font-size: 17px;
  font-weight: 700;
  color: #50555b;
}

.training-header {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 12px 16px;
}

.course-container {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin: 16px 0;
}

.status-header {
  font-size: 23px;
  font-weight: 300;
  height: 49px;
  line-height: 49px;
}

.training-container {
  display: flex;
  padding: 0 16px 16px 16px;
}

.autocomplete-filter {
  width: 150px;
  margin-right: 16px;
  font-size: 13px;
}

.autocomplete-filter input {
  font-size: 13px;
}

.MuiAutocomplete-listbox,
.filter-options-list li,
.filter-option,
.filter-option-group {
  padding: 5px;
  font-size: 13px;
}

.filter-option-group {
  padding-left: 5px;
}

.filter-option-group:hover,
.filter-option:hover {
  background-color: hsl(215, 15%, 97%);
}

.filter-option.selected,
.filter-option-group.selected {
  background-color: #e0f0f6;
}

.assign-form-text {
  font-size: 13px;
}

.assign-button-container button {
  font-size: 13px;
}