.download-form-button {
  background-color: var(--blue);
  color: var(--white);
  text-transform: uppercase;
  display: inline-flex;
  line-height: 1;
  padding: 1em 1.75em;
  border-radius: 3px;
  text-align: center;
  transition: all 0.3s;
  border: 0;
  cursor: pointer;
  gap: 1em;

  &:hover {
    background-color: var(--dark-blue);
  }

  &:disabled {
    background-color: var(--dark-grey);
  }
}

.download-status-message {
  max-width: 190px;
  font-size: 0.85rem;
  font-weight: bold;
  color: initial;
}
