.chart-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  width: 175px;
}

.chart-donut {
  height: 96px;
}

.chart-osha-hipaa {
  background-image: url('../icons/osha_hipaa.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40%;
}

.chart-training {
  background-image: url('../icons/graduation-cap-solid.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 35%;
}

.chart-text {
  color: #2C2F32;
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 4px;
}

.chart-waterline {
  background-image: url('../icons/waterline_icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 40%;
}

.compliance-count {
  color: #2C2F32;
  display: flex;
  justify-content: space-evenly;
}

.compliance-count:not(:last-child) {
  margin-bottom: 4px;
}

.compliance-dot {
  border-radius: 50%;
  display: inline-block;
  height: 11px;
  width: 11px;
  margin-right: 4px;
}

.compliance-dot-count {
  font-size: 13px;
}

.compliance-dot-container {
  display: flex;
  align-items: center;
}