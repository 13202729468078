.download-forms-button {
  background-color: #0085b7;
  color: rgba(256, 256, 256, 1);
  text-transform: capitalize;
  display: inline-flex;
  line-height: 1;
  padding: .4em .8em;
  border-radius: .2rem;
  text-align: center;
  border: 0;
  cursor: pointer;
  gap: 1em;

  &:disabled {
    background-color: #dcdcdc;
    color: #a3a3a3;
  }
}