.component-details {
    font-size: 1.5em;
    font-weight: bold;
    padding-left: 1em;
    margin-top: unset;
}

.component-container {
    color: #50555B;
    padding: 1em 0;
    margin-bottom: 1em;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0.2rem;
}


.component-section-container {
    display: grid;
    overflow-x: scroll;
    grid-auto-flow: column;
    grid-row-gap: 1em;
    grid-template-rows: auto auto;
    grid-auto-columns: 120px;
    overflow-x: auto;
    padding: 0 1em;
}

.component-section {
    display: grid;
    grid-template-rows: subgrid;
    align-items: start;
    justify-content: center;
    justify-items: center;
    text-align: center;
    grid-row: 1 / 3;
}

.component-section__name {
    grid-row: 1;
    font-size: 13px !important;
}

.component-section__compliance {
    grid-row: 2;
}

.appointment {
    color: #27C24C;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.osha-hipaa-label {
    font-weight: bold;
    margin: 1em 0;
}


.checklist-certification-message {
    font-weight: normal;
    font-size: 1rem;
}

.component-scrim {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.component-upsale-message {
    margin-left: 1.5em !important;
    width: 30em;
}
.MuiListItem-root.form-list-item {
    height: 35px;
}