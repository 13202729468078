.sidebar-icon,
.alt-icon {
  width: 35px;
  margin-right: 8px;
  color: #50545a;
}

.sidebar-text {
  color: #50555b;
  font-weight: 600;
}

.alt-icon {
  display: flex;
  justify-content: center;
}

.alt-icon img {
  width: 16px;
}

.arrow-icon {
  color: #bfc6c8;
}

.gray-icon {
  color: #50545a;
}