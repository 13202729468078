.groups-container .table-subheader {
  min-height: 65px;
}

#group-transfer-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#group-transfer-available-container, 
#group-transfer-members-container,
#group-transfer-buttons-container {
  width: 100%;
}

#group-transfer-buttons {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
}

#group-transfer-all-right,
#group-transfer-right,
#group-transfer-left,
#group-transfer-all-left {
  transform: rotate(90deg);
}

@media screen and (min-width: 990px) {
  #group-transfer-container {
    flex-direction: row;
  }

  #group-transfer-available-container,
  #group-transfer-members-container {
    width: 42.5%;
  }

  #group-transfer-buttons-container {
    width: 15%;
  }

  #group-transfer-buttons {
    flex-direction: column;
  }

  #group-transfer-all-right,
  #group-transfer-right,
  #group-transfer-left,
  #group-transfer-all-left {
    transform: rotate(0deg);
  }
}
