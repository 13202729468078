.video-guides-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
}

.video-container {
  text-align: center;
  flex: 0 0 33%;
  padding: 0 24px;
  margin-bottom: 32px;
}

.video {
  position: relative;
  padding-bottom: 56.25%;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}