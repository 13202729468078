.table-toolbar {
  display: flex;
  padding: 1em 0;
  align-items: center;
}

.empty-table-message {
  flex: 1;
  color: red;
}
