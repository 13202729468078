.wrapper {
  --blue: #0086b8;
  --dark-blue: #191970;
  --white: #fff;
  --light-grey: #eeeeee;
  --dark-grey: #51555b;

  max-width: 1140px;
  margin: auto;
  font-family: 'Open Sans', sans-serif;
  overflow: auto;
  padding: 0 1em;

  > * {
    margin-bottom: 1em;
  }

  h1 {
    font-weight: 300;
    line-height: 1;
    font-size: 3em;
    margin-top: 1.75em;
    text-align: center;
  }

  h2 {
    font-weight: 700;
    font-size: 1em;
  }

  ul {
    list-style: none;
    padding-left: unset;
  }

  li {
    color: var(--blue);
    font-size: 1.5em;
    display: flex;
    justify-content: space-between;
  }

  p {
    line-height: 1.7;
  }

  hr {
    border: 1px solid var(--light-grey);
    margin: 2em 4em;
  }
}
