.page-header-container {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-bottom: solid 1px #DEE5E7;
}

.page-header-icon {
  color: #50545A;
  margin-right: 8px;
  font-size: 20px;
}

.page-header-name {
  font-size: 23px;
  font-weight: 300;
  color: #50555B;
  margin-top: unset;
  margin-bottom: unset;
}

.custom-image {
  margin-right: 8px;
}
