body {
  margin: 0;
  padding: 0;
  height: 100vh;
  color: #50555b;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  height: 100%;
  background-color: rgb(250, 250, 250);
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.background-danger {
  background-color: #f05050;
}

.background-inactive {
  background-color: #bfc6c8;
}

.background-success {
  background-color: #27c24c;
}

.background-warning {
  background-color: #f4b122;
}

.block {
  display: block;
}

.body-header-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: auto;
}

.body-header-container > div:first-child {
  height: 100%;
  overflow-y: scroll;
}

.body-footer-container {
  display: flex;
}

.bold {
  font-weight: bold;
}

.color-danger {
  color: #f05050;
}

.color-inactive {
  color: #bfc6c8;
}

.color-primary {
  color: #0086b8;
}

.color-success {
  color: #27c24c;
}

.color-warning {
  color: #f4b122;
}

.content-container {
  display: flex;
  height: calc(100vh - 50px);
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.compliance-box {
  border-radius: 15%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
}

.compliance-circle {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.compliance-circle__medium {
  height: 15px;
  width: 15px;
}

.compliance-circle__large {
  height: 20px;
  width: 20px;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: start;
}

.flex-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.f-s-13 {
  font-size: 13px;
}

.f-s-26 {
  font-size: 26px;
}

.f-w-500 {
  font-weight: 500;
}

.m-12 {
  margin: 12px;
}

.m-16 {
  margin: 16px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-16 {
  margin-left: 16px !important;
}

.m-l-32 {
  margin-left: 32px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-12 {
  margin-right: 12px !important;
}

.m-r-16 {
  margin-right: 16px;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-16 {
  margin-top: 16px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.p-l-12 {
  padding-left: 12px;
}

.p-r-12 {
  padding-right: 12px;
}

.c-g-16 {
  column-gap: 16px;
}

.pointer {
  cursor: pointer;
}

.link {
  color: #19a9d5;
  cursor: pointer;
}

.muted-text {
  color: #bfc6c8;
}

@media screen and (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

textarea {
  font-family: inherit;
}

.text-u-l {
  text-decoration: underline;
}

.text-info {
  color: #0084b7;
}

.contact-us.flex {
  align-content: flex-start;
  flex-direction: column;
}

.contact-us-section.item {
  flex-basis: 100%;
}

.contact-us-section.item:nth-child(2) {
  order: 1;
}

.contact-us-section.blank-row {
  height: 0;
  flex-basis: 100%;
}

.form-control {
  display: block;
  width: 100%;
  border-color: #cfdadd;
  border-radius: 2px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #cfdadd;
  transition: border-colorease-in-out .15s, box-shadowease-in-out .15s;
}

.form-control:focus {
  outline: 0 !important;
  border-color: #23b7e5;
}

@media screen and (min-width: 1200px) {
  .contact-us.flex {
    flex-wrap: wrap;
    flex-direction: row;
  }

  input.form-control {
    width: 66%;
  }

  .contact-us-section.item {
    max-width: 33%;
  }

  .contact-us-section.item:nth-child(2) {
    order: 0;
  }
}

input.form-control {
  height: 32px;
}

.contact-form-message-error {
  border: 1px solid #f05050;
}

.contact-form-submit-error {
  color: #f05050;
}
