.footer-container {
  z-index: 2 !important;
  padding: 6px 0;
  width: 100%;
  border-top: 1px solid #dee5e7;
  background-color: #edf1f2;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content-left {
  display: flex;
  align-items: center;
}

.footer-content-right {
  margin-right: 16px;
}
