.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #0086b8;
  padding: 0 0 0 16px;
}

.company-logo {
  width: 150px;
  position: relative;
  top: 3px;
}

.button-color-black {
  color: #000 !important;
}

.nav-button {
  width: 100%;
  font-size: 13px;
}

.navbar-right-content {
  display: flex;
  align-items: center;
  color: #85deff;
  font-size: 13px;
}
