.box-header {
  padding: 8px 16px;
  background-color: #f6f8f8;
  border: none;
  color: #50555B;
  font-size: 13px;
  font-weight: 700;
}

.filter-container {
  display: flex;
  padding: 16px;
}

.m-r-25 {
  margin-right: 25px;
}