.coverBg {
  width: 100%;
  height: 80px;
  background: #0086b8;
  background-size: 260%;
  border-radius: 5px 5px 0 0;
}

.iframe-sales-page {
  width: 100%;
  height: 100vh;
  border: none;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  font-weight: 300;
}

.header-image {
  margin-right: 8px;
  width: 1.5rem;
  height: 1.5rem;
}
