.button-row {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
}

.tableContainer {
  box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2);
}
