.taskName {
    font-weight: bold;
}

.table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.cellWrap {
    overflow-wrap: anywhere;
    text-wrap: balance;
}

.whitespace {
    white-space: break-spaces;
}

/* App.css is unstyling anchor tags so we have to re-apply basic styles here */
.link {
    color: -webkit-link;
    text-decoration: underline;
}
