.waterline-container {
  padding: 0 16px 16px 16px;
}

.instructions-container {
  font-size: 12px;
  font-weight: bold;
}

.instructions {
  font-size: 16px;
}

.room-table-header {
  display: flex;
  padding: 12px 16px;
  justify-content: flex-end;  
}

.download-box {
  background-color: #ffffff;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 38px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -8px;
  margin-right: -13px;
  cursor: pointer;
}

.waterline-table-subheader {
  border-top: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}

.next-steps-accordion .MuiAccordion-root {
  margin: 0;
}
